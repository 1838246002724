<template>
  <v-col cols="12" md="6">
    <skeleton-form :count="5" v-if="Object.keys(profile).length === 0" />
    <v-card v-else outlined shaped class="tw-shadow-xl tw-mt-4 tw-p-4">
      <v-form @submit.prevent="submit">
        <h3>Information Detail</h3>
        <v-divider class="my-2" />
        <label>Username</label>
        <v-text-field
          label="Username"
          dense
          outlined
          single-line
          v-model="form.username"
        ></v-text-field>
        <label>Email</label>
        <v-text-field
          label="Email"
          dense
          outlined
          single-line
          v-model="form.email"
        ></v-text-field>
        <label>Full Name</label>
        <v-text-field
          label="Full Name"
          dense
          outlined
          single-line
          v-model="form.name"
          :error-messages="errors.name"
        ></v-text-field>
        <label>Number Phone</label>
        <v-text-field
          label="Number Phone"
          dense
          outlined
          single-line
          v-model="form.phone_number"
          :error-messages="errors.phone_number"
        ></v-text-field>
        <label>WhatsApp Number</label>
        <v-text-field
          label="WhatsApp Phone"
          dense
          outlined
          single-line
          v-model="form.vendor.whatsapp_number"
          :error-messages="errors.whatsapp_number"
        ></v-text-field>

        <label>Address</label>
        <v-textarea
          auto-grow
          rows="4"
          label="Address"
          dense
          outlined
          single-line
          v-model="form.vendor.address"
          :error-messages="errors.address"
        ></v-textarea>
        <v-btn
          depressed
          :loading="loading"
          type="submit"
          color="primary"
          class="tw-tracking-normal tw-capitalize tw-font-medium tw-text-base"
          >Submit</v-btn
        >
      </v-form>
    </v-card>
  </v-col>
</template>

<script>
import form from "@/mixins/form";
import Swal from "sweetalert2";
import { profileUpdate } from "@/api/developer";
import SkeletonForm from "./SkeletonForm.vue";
export default {
  components: { SkeletonForm },
  mixins: [form],
  props: {
    profile: {
      type: Object,
      required: true,
    },
    currentUser: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    form: {
      get() {
        return this.profile;
      },
    },
  },
  methods: {
    async submit() {
      try {
        this.$store.dispatch("auth/setLoading");
        const { data } = await profileUpdate(this.form);
        const payload = { profile: data.data };
        this.$store.dispatch("auth/saveProfile", payload);
        await this.currentUser();
        this.$store.dispatch("auth/setLoading");
        Swal.fire({
          icon: "success",
          title: data.message,
          timer: 1500,
        });
        this.errors = {};
      } catch (error) {
        this.$store.dispatch("auth/setLoading");
        if (error.response) {
          this.errors = error.response.data.errors;
        } else {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style></style>
