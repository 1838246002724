<template>
  <v-col cols="12" md="6">
    <skeleton-form :count="3" v-if="Object.keys(profile).length === 0" />
    <v-card v-else outlined shaped class="tw-shadow-xl tw-mt-4 tw-p-4">
      <v-form @submit.prevent="submit">
        <div class="tw-flex">
          <h3>Change Password</h3>
          <v-spacer></v-spacer>
          <v-btn icon @click.prevent="errors = {}">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </div>
        <v-divider class="my-2" />
        <div>
          <label>Old Password</label>
          <v-text-field
            label="Old Password"
            dense
            outlined
            single-line
            :type="typeOld"
            append-icon="mdi-eye"
            @click:append="changeTypeOldPass"
            v-model="form.old_password"
            :error-messages="errors.old_password"
          ></v-text-field>
        </div>
        <div>
          <label>New Password</label>
          <v-text-field
            label="New Password"
            dense
            outlined
            single-line
            :type="typeNew"
            append-icon="mdi-eye"
            @click:append="changeTypeNewPass"
            v-model="form.new_password"
            :error-messages="errors.new_password"
          ></v-text-field>
        </div>
        <div>
          <label>Confirm Password</label>
          <v-text-field
            label="Confirm Password"
            dense
            outlined
            single-line
            :type="typeConfirm"
            append-icon="mdi-eye"
            @click:append="changeTypeConfirmPass"
            v-model="form.confirm_password"
            :error-messages="errors.confirm_password"
          ></v-text-field>
        </div>
        <v-btn
          depressed
          :loading="loading"
          type="submit"
          color="primary"
          class="tw-tracking-normal tw-capitalize tw-font-medium tw-text-base"
          >Submit</v-btn
        >
      </v-form>
    </v-card>
  </v-col>
</template>

<script>
import { changePassword } from "@/api/developer";
import Swal from "sweetalert2";
import form from "@/mixins/form";
import SkeletonForm from "./SkeletonForm.vue";
export default {
  mixins: [form],
  components: { SkeletonForm },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    currentUser: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      typeOld: "password",
      typeNew: "password",
      typeConfirm: "password",
      form: {
        old_password: "",
        new_password: "",
        confirm_password: "",
      },
    };
  },
  methods: {
    changeTypeConfirmPass() {
      this.typeConfirm === "password"
        ? (this.typeConfirm = "text")
        : (this.typeConfirm = "password");
    },
    changeTypeOldPass() {
      this.typeOld === "password"
        ? (this.typeOld = "text")
        : (this.typeOld = "password");
    },
    changeTypeNewPass() {
      this.typeNew === "password"
        ? (this.typeNew = "text")
        : (this.typeNew = "password");
    },
    clearForm() {
      this.form.old_password = '',
      this.form.new_password = '',
      this.form.confirm_password = ''
    },
    async submit() {
      try {
        this.$store.dispatch("auth/setLoading");
        const { data } = await changePassword(this.form);
        await this.currentUser();
        this.$store.dispatch("auth/setLoading");
        this.clearForm()
        this.errors = {};
        Swal.fire({
          icon: "success",
          title: data.message,
          timer: 1500,
        });
      } catch (error) {
        this.$store.dispatch("auth/setLoading");
        if (error.response) {
          this.errors = error.response.data.errors;
        } else {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style></style>
