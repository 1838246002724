<template>
  <v-container fluid>
    <v-card tile flat>
      <card-profile :current-user="getProfile" :profile="profile" />
      <v-card-text>
        <v-row>
          <profile-edit-information :current-user="getProfile" :profile="profile" />
          <profile-change-password :current-user="getProfile" :profile="profile" />
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { currentUser } from "@/api/auth";
import CardProfile from "../components/CardProfile.vue";
import ProfileEditInformation from '../components/ProfileEditInformation.vue';
import ProfileChangePassword from '../components/ProfileChangePassword.vue';

export default {
  components: { CardProfile, ProfileEditInformation, ProfileChangePassword },
  data() {
    return {
      profile: {},
    };
  },
  async created() {
    await this.getProfile();
  },
  methods: {
    async getProfile() {
      const {
        data: { data },
      } = await currentUser();
      this.profile = data;
    },
  },
};
</script>

<style></style>
