<template>
  <v-col cols="6">
    <v-dialog transition="dialog-bottom-transition" max-width="600">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          depressed
          rounded
          block
          v-bind="attrs"
          v-on="on"
          color="primary"
          :class="
            $vuetify.breakpoint.mdAndUp
              ? 'tw-tracking-normal tw-capitalize tw-font-medium tw-text-lg'
              : 'tw-tracking-normal tw-capitalize tw-font-medium tw-text-base'
          "
        >
          <v-icon class="mr-2 tw-text-2xl">mdi-image-outline</v-icon>
          thumbnail
        </v-btn>
      </template>
      <template v-slot:default="dialog">
        <v-card tile>
          <v-img contain :aspect-ratio="1.5" :src="thumbnail"></v-img>
          <div
            class="tw-w-full tw-h-10 tw-bg-black tw-bg-opacity-20 tw-flex tw-absolute tw-z-10 tw-top-0 tw-p-1"
          >
            <v-btn icon dark @click="dialog.value = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              icon
              dark
              @click="(dialog.value = false), (modalEdit = true)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </v-card>
      </template>
    </v-dialog>
    <!-- edit section -->
    <v-dialog
      v-model="modalEdit"
      transition="dialog-bottom-transition"
      max-width="500"
    >
      <v-card>
        <div class="tw-p-4">
          <vue-dropzone
            id="dropzone"
            ref="myVueDropzone"
            :options="dropzoneOptions"
            @vdropzone-success="onComplete"
            @vdropzone-error="showError"
            :useCustomSlot="true"
          >
            <div class="dropzone-custom-content">
              <span class="dropzone-custom-title">
                Upload image here
              </span>
            </div>
          </vue-dropzone>
          <v-alert
            dense
            border="left"
            type="error"
            class="mt-2"
            v-if="errors.image"
          >
            {{ errors.image }}
          </v-alert>
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            outlined
            class="tw-capitalize tw-tracking-wide"
            color="primary"
            max-width="200"
            width="120"
            type="submit"
            @click="modalEdit = false"
            >Cancel</v-btn
          >
          <v-btn
            depressed
            class="tw-capitalize tw-tracking-wide"
            color="primary"
            max-width="200"
            width="120"
            type="submit"
            :loading="loading"
            @click.prevent="triggerSend()"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end -->
  </v-col>
</template>

<script>
import form from "@/mixins/form";
import Swal from "sweetalert2";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  mixins: [form],
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    thumbnail: {
      type: String,
      required: true,
    },
    currentUser: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      modalEdit: false,
      dropzoneOptions: {
        autoProcessQueue: false,
        removeAllFiles: true,
        addRemoveLinks: true,
        maxFilesize: 2,
        maxFiles: 1,
        acceptedFiles: "image/*,.svg",
        headers: { Authorization: "Bearer " + this.$store.state.auth.token },
        url:
          `${process.env.VUE_APP_API}/vendors/edit-vendor-thumbnail`,
      },
    };
  },
  methods: {
    triggerSend() {
      this.$store.dispatch("auth/setLoading");
      this.$refs.myVueDropzone.processQueue();
    },
    async onComplete(files, response) {
      this.$store.dispatch("auth/setLoading");
      (await response.status) === "success"
        ? Swal.fire({
            icon: "success",
            title: response.message,
            timer: 1500,
          })
        : null;
      this.modalEdit = false;
      this.$refs.myVueDropzone.removeAllFiles();
      await this.currentUser();
    },
    showError(file, message) {
      if (message.status === "Error") {
        this.errors = {
          image: message.message,
        };
      }
      console.log(message);
    },
  },
};
</script>

<style>
#dropzone .dz-preview .dz-image > img {
  height: 200px !important;
}

.vue-dropzone > .dz-preview .dz-remove {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.dropzone .dz-preview .dz-error-message {
  padding: 0.5em 0.7em !important;
  font-size: 11px !important;
}

.vue-dropzone {
  height: 250px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
</style>
