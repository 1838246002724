import api from "./api";

export const login = (payload) =>
  api.post("/login", {
    username: payload.username,
    password: payload.password,
  });

export const logout = () => api.post("/logout");

export const currentUser = () =>
  api
    .get("/users/current-user")
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });
