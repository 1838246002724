<template>
  <v-card-text>
    <v-row align="center">
      <v-col cols="12" md="6">
        <skeleton-card v-if="Object.keys(profile).length === 0" />
        <v-card
          outlined
          shaped
          min-height="250"
          class="tw-shadow-xl tw-p-4 tw-flex tw-items-center"
          v-else
        >
          <v-row justify="center" align="center">
            <modal-view-logo :logo="profile.vendor.logo" />
            <v-col cols="6">
              <div class="tw-flex tw-items-center">
                <v-btn
                  icon
                  depressed
                  dark
                  :small="$vuetify.breakpoint.mdAndUp"
                  :x-small="$vuetify.breakpoint.mdAndDown"
                  color="primary"
                >
                  <v-icon>mdi-cog</v-icon>
                </v-btn>

                <span
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'ml-2 tw-text-gray-400 tw-tracking-normal tw-text-base tw-font-medium'
                      : 'ml-2 tw-text-gray-400 tw-tracking-normal tw-text-sm tw-font-medium'
                  "
                  >{{ profile.role.name }}</span
                >
              </div>
              <h2
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'tw-text-gray-800 tw-font-normal tw-text-xl'
                    : 'tw-text-gray-800 tw-font-normal tw-text-lg'
                "
              >
                {{ profile.name }}
              </h2>
              <h6
                :class="
                  $vuetify.breakpoint.mdAndUp
                    ? 'tw-text-gray-400 tw-text-lg tw-font-normal'
                    : 'tw-text-gray-400 tw-text-base tw-font-normal'
                "
              >
                {{ profile.email }}
              </h6>
            </v-col>
            <modal-edit-logo
              :current-user="currentUser"
              :logo="profile.vendor.logo"
            />
            <modal-edit-thumbnail
              :current-user="currentUser"
              :thumbnail="profile.vendor.thumbnail"
            />
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="6" v-if="$vuetify.breakpoint.mdAndUp">
        <v-skeleton-loader
          v-if="Object.keys(profile).length === 0"
          class="mx-auto"
          type="image"
        ></v-skeleton-loader>
        <v-img
          v-else
          style="transform: scaleX(-1);"
          contain
          max-height="200"
          src="https://ik.imagekit.io/5zmdzllq3/Detailed_information_h5dUZHWvj.svg"
        ></v-img>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import SkeletonCard from "../components/SkeletonCard.vue";
import ModalEditThumbnail from "./ModalEditThumbnail.vue";
import ModalEditLogo from "./ModalEditLogo.vue";
import ModalViewLogo from "./ModalViewLogo.vue";
export default {
  components: {
    SkeletonCard,
    ModalEditThumbnail,
    ModalEditLogo,
    ModalViewLogo,
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    currentUser: {
      type: Function,
      required: true,
    },
  },
  methods: {
    toggleForm() {
      this.$emit("toggleForm");
    },
  },
};
</script>

<style></style>
